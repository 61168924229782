import React, { FunctionComponent } from "react";
import "../scss/main.scss";
import { GoogleAnalyticsInit } from "./GoogleAnalyticsInit";
import { Logo } from "./Logo";

interface IProps {}

export const Layout: FunctionComponent<IProps> = ({ children }: React.PropsWithChildren<IProps>) => {
    return (
        <>
            <GoogleAnalyticsInit propertyId={`${process.env.GATSBY_GOOGLE_ANALYTICS_PROPERTY_ID}`} />
            <div className={"container"}>
                <div className={"width-container"}>
                    <Logo>
                        <h1>Like a Photon Creative</h1>
                    </Logo>
                </div>
                {children}
                <footer>© {new Date().getFullYear()} Like a Photon Creative</footer>
            </div>
        </>
    );
};
