import React, { createElement, Component } from "react";
import { PropertyLoader, RenderPropRender } from "./PropertyLoader";

export enum GuardState {
    Unchecked = 0,
    Checked = 1,
    Failed = 2,
}

export enum SubmissionState {
    Passed = 1,
    Failed = 2,
}

interface IState {
    guardState: GuardState;
}

export interface IAuthenticationSubmission {
    password: string;
}

interface IProps {
    checker: () => Promise<GuardState>;
    authenticationSubmitter: (submission: IAuthenticationSubmission) => Promise<SubmissionState>;
    deauthenticationSubmitter: () => Promise<SubmissionState>;
    render: RenderPropRender<ILoadedProps>;
}

export interface ILoadedProps {
    authenticated: GuardState;
    submitAuthentication: (submission: IAuthenticationSubmission) => Promise<SubmissionState>;
    submitDeauthentication: () => Promise<SubmissionState>;
}

export class ClientAuthenticationGuard extends Component<IProps, IState> {
    public render() {
        const submitAuthentication = this.props.authenticationSubmitter;
        const submitDeauthentication = this.props.deauthenticationSubmitter;
        const render = (loadedProperties: ILoadedProps) => {
            return this.props.render(loadedProperties);
        };

        const loader = async (): Promise<ILoadedProps> => {
            const authenticated = await this.props.checker();
            return { authenticated, submitAuthentication, submitDeauthentication };
        };

        return createElement(PropertyLoader, { render, loader }, <div>Authenticating...</div>);

        // return <PropertyLoader render={render} loader={loader} />;
    }
}
