import React, { FunctionComponent, PropsWithChildren } from "react";
import ReactGA from "react-ga";

interface IProps {
    propertyId: string;
}

export const GoogleAnalyticsInit: FunctionComponent<IProps> = (props: PropsWithChildren<IProps>) => {
    ReactGA.initialize(props.propertyId);
    ReactGA.pageview(window.location.pathname + window.location.search);

    return <></>;
};
