import Cookie from "js-cookie";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { ClientAuthenticationGuard, GuardState, IAuthenticationSubmission, ILoadedProps, SubmissionState } from "./ClientAuthenticationGuard";
import { RenderPropRender } from "./PropertyLoader";

interface IProps {
    render: RenderPropRender<ILoadedProps>;
    password?: string;
}

export const CookieGuard: FunctionComponent<IProps> = (props: PropsWithChildren<IProps>) => {
    const correctPassword = props.password || "Maybe-Nest-Surface-2";

    const checker = async () => {
        const auth = Cookie.get("auth");

        if (auth === correctPassword) {
            return GuardState.Checked;
        } else {
            return GuardState.Failed;
        }
    };

    const authenticationSubmitter = async (submission: IAuthenticationSubmission) => {
        if (submission.password === correctPassword) {
            Cookie.set("auth", correctPassword, { expires: 7 });
            return SubmissionState.Passed;
        }
        return SubmissionState.Failed;
    };

    const deauthenticationSubmitter = async () => {
        Cookie.remove("auth");
        return SubmissionState.Passed;
    };

    return <ClientAuthenticationGuard checker={checker} render={props.render} authenticationSubmitter={authenticationSubmitter} deauthenticationSubmitter={deauthenticationSubmitter} />;
};
