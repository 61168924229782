import React, { FunctionComponent, PropsWithChildren } from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

interface IMetaAsName {
    name: string;
    content?: string;
}

interface IMetaAsProperty {
    property: string;
    content?: string;
}

type Meta = (IMetaAsName | IMetaAsProperty)[];

interface IProps {
    description?: string;
    lang: string;
    meta: Meta;
    title: string;
}

export const SEO: FunctionComponent<IProps> = ({ description, lang, meta, title }: PropsWithChildren<IProps>) => {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                    }
                }
            }
        `,
    );

    const metaDescription = `${description || site.siteMetadata.description}`;

    const fullMeta: Meta = [
        {
            name: `description`,
            content: metaDescription,
        },
        {
            property: `og:title`,
            content: title,
        },
        {
            property: `og:description`,
            content: metaDescription,
        },
        {
            property: `og:type`,
            content: `website`,
        },
        {
            name: `twitter:card`,
            content: `summary`,
        },
        {
            name: `twitter:creator`,
            content: `${site.siteMetadata.author}`,
        },
        {
            name: `twitter:title`,
            content: title,
        },
        {
            name: `twitter:description`,
            content: metaDescription,
        },
    ];

    const mergedMeta = fullMeta.concat(meta);

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={mergedMeta}
            link={[
                {
                    href: "https://fonts.googleapis.com/css?family=Raleway:400,700&display=swap",
                    rel: "stylesheet",
                },
            ]}
        />
    );
};

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};
