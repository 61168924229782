import React, { Component } from "react";
import { LoaderState } from "../interfaces";

export type RenderPropRender<TLoadedProps> = (state: TLoadedProps) => JSX.Element;
export type RenderPropLoader<TInitialProps, TLoadedProps> = (initialProps: TInitialProps) => Promise<TLoadedProps>;

export interface IPropertyLoaderProps<TInitialProps, TLoadedProps> {
    render: RenderPropRender<TLoadedProps>;
    loader: RenderPropLoader<IPropertyLoaderProps<TInitialProps, TLoadedProps>, TLoadedProps>;
}

interface IState {
    loaderState: LoaderState;
}

export class PropertyLoader<TInitialProps extends IPropertyLoaderProps<TInitialProps, TLoadedProps>, TLoadedProps extends {}> extends Component<TInitialProps, IState> {
    private loadedProperties: TLoadedProps | undefined;

    public constructor(props: TInitialProps) {
        super(props);
        this.state = { loaderState: LoaderState.Unloaded };
    }

    /**
     * When the component mounts, if loading has not yet begun, do so now.
     */
    public async componentDidMount() {
        if (this.state.loaderState === LoaderState.Unloaded) {
            this.setState({ loaderState: LoaderState.Loading });
            try {
                this.loadedProperties = await this.props.loader(this.props);
                this.setState({ loaderState: LoaderState.Loaded });
            } catch (error) {
                this.loadedProperties = undefined;
                this.setState({ loaderState: LoaderState.Failed });
                throw error;
            }
        }
    }

    public render() {
        const loadedProperties = this.loadedProperties;
        const { children } = this.props;
        if (loadedProperties) {
            return this.props.render(loadedProperties);
        } else {
            return <>{children}</>;
        }
    }
}
