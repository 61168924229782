import { Component, FunctionComponent } from "react";

export enum LoaderState {
    Unloaded = 0,
    Loading = 1,
    Loaded = 2,
    Failed = 3,
}

export type ComponentConstructor<TProps = {}, TState = {}> = {
    new (props: TProps): Component<TProps, TState>;
};

export type ComponentClassOrFunction<TProps = {}, TState = {}> = ComponentConstructor<TProps, TState> | FunctionComponent<TProps>;
